<template>
  <div class="card">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-6">
          <strong>Invoice History</strong>
        </div>
        <div class="col-6 text-right">
          <AddressChange
            :visible="visible"
            @close="visible = false"
            @success="onSuccess"
          >
            <a-button type="primary" ghost size="small" @click="visible = true">
              <i class="fal fa-pen mr-2"></i> Change Billing Address
            </a-button>
          </AddressChange>
        </div>
      </div>
    </div>
    <BaseSpinner :loading="loading">
      <table class="table mb-0">
        <thead class="thead-light">
          <tr>
            <th>Number</th>
            <th>Created</th>
            <th>Amount Due</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="invoice in invoices" :key="invoice.id">
            <td>
              {{ invoice.number }}
              <span v-if="invoice.status === 'draft'" class="badge badge-info ml-2">Draft</span>
              <span v-else-if="invoice.status === 'paid'" class="badge badge-success ml-2">Paid</span>
              <span v-else-if="invoice.status === 'void'" class="badge badge-info ml-2">Void</span>
              <a-button v-else type="link" size="small" :loading="loadingInvoice" @click="onRetry(invoice.id)">
                <i class="fal fa-redo mr-2"></i> Retry Charge
              </a-button>
            </td>
            <td>
              {{ invoice.created }}
            </td>
            <td>
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <strong>{{ invoice.amount_due.toLocaleString(undefined, { style: 'currency', currency: invoice.currency }) }}</strong>
                </div>
                <div v-if="invoice.link">
                  <a-button :href="invoice.link" type="link" size="small">
                    <i class="fal fa-file-pdf mr-2"></i> Download
                  </a-button>
                </div>
              </div>
            </td>
          </tr>
          <tr class="text-muted text-center" v-if="!invoices.length">
            <td colspan="3">
              <i class="fad fa-empty-set mr-1"></i> No invoices available.
            </td>
          </tr>
        </tbody>
      </table>
    </BaseSpinner>
  </div>
</template>

<script>
import AddressChange from '@/components/Address/AddressChange'
import BaseSpinner from '@/components/Base/BaseSpinner'

export default {
  components: {
    AddressChange,
    BaseSpinner,
  },

  data() {
    return {
      loading: true,
      loadingInvoice: false,
      visible: false,
      filters: {
        limit: 100,
      },
      hasMore: false,
      invoices: [],
      invoicesCount: null,
    }
  },

  async mounted() {
    await this.listInvoices()
  },

  methods: {
    async listInvoices() {
      try {
        this.loading = true

        var { data } = await this.$httpd.get(`/invoices`, {
          params: this.filters,
        })

        this.hasMore = data.hasMore
        this.invoices = data.invoices
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      } finally {
        this.loading = false
      }
    },
    async onRetry(invoiceId) {
      try {
        this.loadingInvoice = true

        var { data } = await this.$httpd.put(`/invoices/${ invoiceId }`)

        this.$message.success(`Invoice ${ data.number } was successfully paid.`)
        await this.listInvoices()
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      } finally {
        this.loadingInvoice = false
      }
    },
    onSuccess() {
      this.visible = false
      this.$emit('change')
    },
  },
}
</script>
